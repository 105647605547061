import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebaseConfig from 'configs/FirebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

// Firebase utils
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
  auth,
  currentUser,
  googleAuthProvider,
  facebookAuthProvider,
};
















// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/firestore'
// import firebaseConfig from 'configs/FirebaseConfig'
// import { getMessaging, getToken, onMessage } from 'firebase/messaging'

// const firebaseApp = firebase.initializeApp(firebaseConfig)

// // const messaging = firebase.messaging()

// // firebase utils
// // const db = firebase.firestore()
// const auth = firebase.auth()
// const currentUser = auth.currentUser
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
// const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()

// export {
//   // db,
//   auth,
//   currentUser,
//   googleAuthProvider,
//   facebookAuthProvider,
//   // messaging,
// }
