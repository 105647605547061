import fetch from 'auth/FetchInterceptor';

const subadminService = {};
const apiRoute = '/subAdmins';


subadminService.getAllSubAdmins = async function (query = '') {
  try {
    const res = await fetch({
      url: `${apiRoute}/all?${query}`,
      method: 'get'
    });
    return res;
  } catch (err) {
    console.log(err, 'show-err');
  }
};


subadminService.getSubAdminById = async function (id) {
  try {
    const res = await fetch({
      url: `${apiRoute}/${id}`,
      method: 'get'
    });
    return res.data;
  } catch (err) {
    console.log(err, 'show-err');
  }
};

subadminService.getProfile = async function () {
  try {
    const res = await fetch({
      url: `${apiRoute}`,
      method: 'get'
    });
    return res;
  } catch (err) {
    console.log(err, 'show-err');
  }
};

subadminService.createUser = async function (data) {
  try {
    const res = await fetch({
      url: `${apiRoute}`,
      method: 'post',
      data
    });
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err, 'show-err');
  }
};

subadminService.editUser = async function (data, id) {
  try {
    const res = await fetch({
      url: `${apiRoute}/${id}`,
      method: 'put',
      data
    });
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err, 'show-err');
  }
};
subadminService.editPermissions = async function (data = {}, id) {
  try {
    const res = await fetch({
      url: `${apiRoute}/${id}/permissions`,
      method: 'put',
      data
    });
    console.log(res);
    return res.data;
  } catch (err) {
    console.log(err, 'show-err');
  }
};

subadminService.getDetailForProfile = async function () {
  try {
    const res = await fetch({
      url: `/users/UserByFirebaseId`,
      method: 'get'
    });
    return res;
  } catch (err) {
    console.log(err, 'show-err');
  }
};

export default subadminService;